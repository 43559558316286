import React, { Fragment } from "react";
import { Spinner } from "reactstrap";

const Preferences = ({ formData, handleChange, nextStep, handleSubmit,loading, preferences }) => {

    const handleOptionChange = (preferenceId, selectedOptionId) => {
        const existingPreferenceIndex = formData.preferences.findIndex(
            (pref) => pref.preference_id === preferenceId
        );

        if (existingPreferenceIndex !== -1) {
            formData.preferences[existingPreferenceIndex] = {
                preference_id: preferenceId,
                option_id: selectedOptionId,
            };
        } else {
            formData.preferences.push({
                preference_id: preferenceId,
                option_id: selectedOptionId,
            });
        }

        handleChange({
            target: {
                name: 'preferences',
                value: formData.preferences,
            },
        });
    };

    return (
        <Fragment>
            <div className="">
                <h5 className="fw-bold mb-4 text-blue-primary">Event Preferences</h5>
                <p className="text-muted mb-4">Please make your selection below:</p>
                <div className="row mb-4">
                    {preferences.map((preference, index) => (
                        <div className="col-12 mb-2" key={index}>
                            <label className="form-label" htmlFor={`inputOption${preference.id}`}>
                                {preference.title}
                            </label>
                            <select
                                className="form-select form-select-sm"
                                id={`inputOption${preference.id}`}
                                name={`preference${preference.id}`}
                                value={
                                    formData.preferences.find((pref) => pref.preference_id === preference.id)?.option_id || ''
                                }
                                onChange={(e) => handleOptionChange(preference.id, e.target.value)}
                            >
                                <option value=""></option>
                                {preference.options.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-end">
                {loading ?
                        <button className="btn btn-sm bg-blue-primary rounded-pill px-4 ms-2" disabled>
                            <Spinner
                                as="span"
                                variant="white"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="grow"
                            />
                        </button> :
                    <button type="button" className="btn btn-sm btn-brown-primary rounded-pill px-4 fw-bold" onClick={handleSubmit} disabled={formData.preferences.length !== preferences.length}>Send RSVP</button>
                 } </div>
            </div>
        </Fragment>
    );
}

export default Preferences;