import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { TbCalendarDue } from "react-icons/tb";
import { BiTimeFive } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import { BiUserPin } from "react-icons/bi";
import { MdMarkEmailRead } from "react-icons/md";
import { convertTo12HourFormat } from "../../../utility/FormateTime";

const Finished = ({event}) => {

    return (
        <Fragment>
            <div className="card mx-auto" style={{width: "100%", maxWidth: 720}}>
                <div className="card-body p-5 d-flex flex-column align-items-center">
                    <header className="d-flex gap-2"><MdMarkEmailRead size={28} color="#4ABA14" /> <h4 className="fw-bold">Event Invite Sent</h4></header>
                    <p>Your guestlist have been sent RSVP links</p>
                    <ul className="nav flex-column mb-4">
                        <li><TbCalendarDue size={18} color="#B9A244" /> <span className="ms-2">{event.date}</span></li>
                        <li><BiTimeFive size={18} color="#B9A244" /> <span className="ms-2">{event.start_time} - {event.end_time}</span></li>
                        <li><IoLocationOutline size={18} color="#B9A244" /> <span className="ms-2">({event.venue})</span></li>
                        <li><BiUserPin size={18} color="#B9A244" /> <span className="ms-2">{event.guests} People invited</span></li>
                    </ul>
                    <p>View event details on the main page</p>
                    <Link to={'/home'} className="text-blue-primary fw-bold text-decoration-underline">Go back to Main page</Link>
                </div>
            </div>
        </Fragment>
    );
}

export default Finished;