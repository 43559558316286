import React, { Fragment, useState } from "react";
import { Spinner } from "reactstrap";
import { api } from "../../../../service/api";

const Authenticate = ({ formData, handleChange, nextStep }) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleAuthenticate = async (event) => {
        // event.preventDefault();
        // setErrors({});
        // setLoading(true);
        // api.post("login", {})
        //     .then(response => {
        //         console.log(response.data);
        //         nextStep();
        //     })
        //     .catch(error => {
        //         console.error(error)
        //     })
        //     .finally(() => setLoading(false));
        nextStep();
    };

    return (
        <Fragment>
            <div className="">
                <h5 className="fw-bold mb-4 text-blue-primary">RSVP authentication</h5>
                <p className="text-muted mb-4">Please enter the email you received the invite link for this event to continue to start your RSVP submission.</p>
                <div className="col-12 mb-4">
                    <label className="form-label" htmlFor="inputEmail">Enter Email Address</label>
                    <div className="input-group">
                        <input type="email" className="form-control form-control-sm" id="inputEmail" defaultValue={formData.guest.email} placeholder="" disabled />
                        {errors.email && <p className="text-danger">{errors.email}</p>}
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    {loading ?
                        <button type="button" className="btn btn-sm btn-brown-primary rounded-pill px-4 fw-bold" disabled={true}>
                            <Spinner
                                as="span"
                                variant="white"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="grow"
                            />
                        </button> :
                        <button type="button" className="btn btn-sm btn-brown-primary rounded-pill px-4 fw-bold" onClick={nextStep}>Authenticate</button>}
                </div>
            </div>
        </Fragment>
    );
}

export default Authenticate;