import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Spinner } from "reactstrap";
import "./style.css";
import logo from "../../assets/logo.png";
import { api, baseURL } from "../../service/api";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { login, saveUser } from "../../service/auth";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const { setAuthenticated, setUser } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .get(baseURL + "/sanctum/csrf-cookie")
      .then(async (response) => {
        try {
          const response2 = await api.post(`/auth/login`, {
            email,
            password,
          });
          setUser(response2.data.data.user);
          login(response2.data.data.access_token);
          setAuthenticated(true);
          saveUser(response2.data.data.user);
          navigate("/home");
        } catch (error) {
          if (error.response) {
            if (error.response.status === 422) {
              setErrors(error.response.data.errors);
            }

            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage("We are having connection problems!");
          }
        } finally {
          setIsLoading(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="container-fluid">
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="text-center mb-2">
          <img src={logo} alt="Eswatini-Railways Logo" width={260} />
        </div>
        <div className="text-center my-3 text-gray-dark">
          <span style={{ fontWeight: 600 }}>Events Portal</span>
        </div>
        <div className="card" style={{ width: "100%", maxWidth: 520 }}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="card-body"
          >
            <h4 className="text-blue-primary fw-bold my-3">Sign In</h4>
            <div className="form-group mb-3">
              <label htmlFor="inputEmail" className="form-label">
                E-mail
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail"
                placeholder="E-mail"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
              />
              {errors.email && <p className="text-danger">{errors.email}</p>}
            </div>
            <div className="form-group mb-4">
              <label htmlFor="inputPassword" className="form-label">
                Password
              </label>
              <div className="input-group">
                <input
                  type="text"
                  value={password}
                  className="form-control"
                  placeholder="Senha"
                  onChange={({ target }) => setPassword(target.value)}
                />
              </div>
              {errors.password && (
                <p className="text-danger">{errors.password}</p>
              )}
            </div>
            <div className="form-group mb-4">
              {isLoading ? (
                <button
                  type="button"
                  className="btn btn-dark bg_tracking text-white w-100"
                  disabled={true}
                >
                  <Spinner
                    as="span"
                    variant="white"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn bg-blue-primary rounded-pill w-100"
                >
                  Sign In
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
