import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { AiOutlineScan } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiSolidTimeFive } from "react-icons/bi";


import { api } from "../../../service/api";

const HomeMobile = ({ children }) => {
    const [tab, setTab] = useState(1);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const toggle = () => setModal(!modal);
    const [event, setEvent] = useState(null)
    // const { id } = useParams();
    const id = 1;
    useEffect(() => {
        loadEvent();
    }, [id]);

    const loadEvent = async () => {
        if (id) {
            try {
                const response = await api.get(`/events/${id}`);
                console.log("Resp - Event Mobile", response.data)
                setEvent(response.data.event);
            } catch (error) {
                console.error(error.response.data)
            }
        }
    };

    return (
        <div className="bg-white">
            {children}
            <div className="shadow fixed-bottom d-flex justify-content-between align-items-end px-4 py-2 border-top bg-white">
                <Link to={'/mobile/home'} className="d-flex flex-column align-items-center text-dark"><BiSolidTimeFive size={18} /><span>History</span></Link>
                <Link to={'/mobile/scan/qrcode'} className="d-flex flex-column align-items-center text-dark"><div className="p-2 rounded-circle" style={{backgroundColor: "#0D5073"}}><AiOutlineScan color="white" size={18} /></div><span>Scann</span></Link>
                <Link to={'/mobile/profile'} className="d-flex flex-column align-items-center text-dark"><AiOutlineUser size={18} /><span>Account</span></Link>
            </div>
        </div>
    );
}

export default HomeMobile;