import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { TbCalendarDue } from "react-icons/tb";
import { BiTimeFive } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";

import GuestsTab from "./Tabs/GuestsTab";
import DetailsTab from "./Tabs/DetailsTab";
import FeedbackTab from "./Tabs/FeedbackTab";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import "./index.css";
import { api } from "../../../service/api";

const DetailsEvent = () => {
  const [tab, setTab] = useState(1);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const toggle = () => setModal(!modal);
  const [event, setEvent] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    loadEvent();
  }, [id,tab]);
  const loadEvent = async () => {
    if (id) {
      try {
        const response = await api.get(`/events/${id}`);
        // console.log("resp - Details", response.data)
        setEvent(response.data.event);
      } catch (error) {
        console.error(error.response.data);
      }
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    const formData = {
      id: id,
      canceletion_reason: message,
    };
    api
      .post("events/cancelation", formData)
      .then((response) => {
        // console.log("resp - Cancelation", response.data);
        loadEvent();
        toggle();
      })
      .catch((error) => {
        console.error(error.response.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      {event ? (
        <>
          <div className="card mb-3">
            <div className="card-body">
              <div className="d-flex flex-wrap justify-content-between align-items-center my-2">
                <h5
                  style={{ fontSize: "23px" }}
                  className="text-nowrap fw-bold"
                >
                  {event.name}
                </h5>
                {event.canceled_at ? (
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    disabled
                  >
                    Event Canceled
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm btn-white text-gray-dark"
                    onClick={toggle}
                  >
                    <AiOutlineClose size={16} /> Cancel Event
                  </button>
                )}
              </div>
              <ul className="nav d-flex gap-4">
                <li>
                  <TbCalendarDue size={18} />
                  <small style={{ fontSize: "13px" }} className="ms-1">
                    {event.date}
                  </small>
                </li>
                <li>
                  <BiTimeFive size={18} />
                  <small style={{ fontSize: "13px" }} className="ms-1">
                    {event.start_time} - {event.end_time}
                  </small>
                </li>
                <li>
                  <IoLocationOutline size={18} />
                  <small style={{ fontSize: "13px" }} className="ms-1">
                    ({event.venue})
                  </small>
                </li>
              </ul>
            </div>
            <div className="card-footer bg-white pb-0">
              <ul className="nav d-flex gap-4">
                <li>
                  <button
                    style={{ fontSize: "13px" }}
                    onClick={() => setTab(1)}
                    className={
                      tab === 1
                        ? "btn btn-sm itemTab activeTab"
                        : "btn btn-sm itemTab"
                    }
                  >
                    Guests
                  </button>
                </li>
                <li>
                  <button
                    style={{ fontSize: "13px" }}
                    onClick={() => setTab(2)}
                    className={
                      tab === 2
                        ? "btn btn-sm itemTab activeTab"
                        : "btn btn-sm itemTab"
                    }
                  >
                    Event Details
                  </button>
                </li>
                <li>
                  <button
                    style={{ fontSize: "13px" }}
                    onClick={() => setTab(3)}
                    className={
                      tab === 3
                        ? "btn btn-sm itemTab activeTab"
                        : "btn btn-sm itemTab"
                    }
                  >
                    Feedback Summary
                  </button>
                </li>
              </ul>
            </div>
          </div>
          
          <div className="mb-4">
            {tab === 1 && <GuestsTab guests={event.guests} id={id} />}
            {tab === 2 && <DetailsTab event={event} />}
            {tab === 3 && (
              <FeedbackTab
                responses={event.responses}
                preferences={event.preferences}
                id={id}
              />
            )}
          </div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalBody className="px-4">
              <h5 style={{ fontSize: "13px" }} className="text-center p-3">
                Cancel Event
              </h5>
              <div>
                <p style={{ marginBottom: "16px" }}>
                  Event Name: <span className="form-label">{event.name}</span>
                </p>
                <div
                  style={{ marginBottom: "16px" }}
                  className="d-flex align-items-center"
                >
                  <span>
                    <span>Date:</span>{" "}
                    <span className="form-label">{event.date}</span>
                  </span>
                  <span style={{ marginLeft: "24px" }}>
                    <span>Time:</span>{" "}
                    <span className="form-label">{event.start_time}</span>
                  </span>
                </div>
                <p style={{ marginBottom: "16px" }}>
                  Venue: <span className="form-label">{event.venue}</span>
                </p>
              </div>
              <p className="text-center mb-4">
                <small style={{ fontSize: "13px" }}>
                  Please provide a cancellation message that will be sent to
                  your guests. Confirm that this is the event you would like to
                  cancel
                </small>
              </p>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Message"
                  id="inputMessage"
                  style={{ height: "150px" }}
                  value={message}
                  onChange={handleMessageChange}
                ></textarea>
                <label htmlFor="inputMessage">Message</label>
              </div>
            </ModalBody>
            <ModalFooter className="border-0">
              <Button
                color="secondary"
                className="btn btn-sm rounded-pill px-4 "
                size="sm"
                outline
                onClick={toggle}
              >
                No, don’t cancel
              </Button>{" "}
              {loading ? (
                <button
                  className="btn btn-sm bg-blue-primary rounded-pill px-4 ms-2"
                  disabled
                >
                  <Spinner
                    as="span"
                    variant="white"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="grow"
                  />
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="btn btn-sm bg-blue-primary rounded-pill px-4 ms-2"
                >
                  Yes, cancel event
                </button>
              )}
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <h1>Loading...</h1>
      )}
    </Fragment>
  );
};

export default DetailsEvent;
