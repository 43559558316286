import React, { Fragment } from "react";
import { TbCalendarDue } from "react-icons/tb";
import { BiTimeFive } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import { FaEnvelopeOpenText } from "react-icons/fa";

const Welcome = ({ formData, nextStep }) => {
  return (
    <Fragment>
      <div className="d-flex flex-column align-items-center text-center">
        <p>
          <FaEnvelopeOpenText size={56} color="#001E64" />
        </p>
        <h5 className="fw-bold">Welcome</h5>
        <p style={{ fontSize: "13px" }} className="mb-1">
          Event Invitation
        </p>
        <ul className="nav flex-column mb-4">
          <li
            className="mb-1 text-muted"
            style={{ fontWeight: "bolder", fontSize: "15px" }}
          >
            <TbCalendarDue size={18} />{" "}
            <span className="ms-2">{formData.date}</span>
          </li>
          <li
            className="mb-1 text-muted"
            style={{ fontWeight: "bolder", fontSize: "15px" }}
          >
            <BiTimeFive size={18} />{" "}
            <span className="ms-2">
              {formData.start_time} - {formData.end_time}
            </span>
          </li>
          <li
            className="mb-1 text-muted"
            style={{ fontWeight: "bolder", fontSize: "15px" }}
          >
            <IoLocationOutline size={18} />{" "}
            <span className="ms-2">({formData.venue})</span>
          </li>
        </ul>
        <p style={{ fontSize: "15px" }}>
          Bringing together key industry decision makers, the agenda will
          showcase the best in local and international project expertise,
          encouraging networking opportunities.
        </p>
        <h6 className="mb-1 fw-bolder">RSVP</h6>
        <p style={{ fontSize: "15px" }}>
          Please confirm your participation at the event by {formData.deadline}.
        </p>
        <p style={{ fontSize: "12px" }} className="text-muted mb-4">
          NB: {formData.note}
        </p>
        <button
          type="button"
          className="btn btn-sm btn-brown-primary rounded-pill px-4 fw-bold"
          onClick={nextStep}
        >
          Continue to RSVP
        </button>
      </div>
    </Fragment>
  );
};

export default Welcome;
