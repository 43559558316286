import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { logout } from "../../../service/auth";
import { MdLogout } from "react-icons/md";

const Profile = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const handleSignOut = () => {
        const advice = "Are you sure you want to log out?";
        if (window.confirm(advice) === true) {
            logout(); 
            navigate('/mobile/getStarted');
        }
    }
    return (
        <Fragment>
            <div style={{ height: "100vh" }}>
                <div className="px-3 pt-4 mb-4" >
                    <h5 className="fw-bolder">Account</h5>
                </div>

                <div className="px-3 mb-4">
                    <p className="mb-0">Event</p>
                    <h6 className="mb-0 fw-bold">{'Event Name Here'}</h6>
                </div>

                <div className="px-3 mb-4">
                    <p className="mb-0">Username</p>
                    <h6 className="mb-0 fw-bold">{user.name}</h6>
                </div>

                <div className="p-2 mb-2">
                    <button type='button' onClick={handleSignOut} className="btn btn-sm btn-white text-blue-light">
                        <MdLogout color="#0D5073" size={15} />
                        <span className="ms-2" style={{ fontWeight: 600 }}>Sign-out</span>
                    </button>
                </div>
            </div>
        </Fragment>
    );
}

export default Profile;