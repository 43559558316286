import React, { Fragment } from "react";
import { BiMessageRoundedCheck } from "react-icons/bi";

const Done = () => {
  const closeTab = () => {
    window.close();
  };

  return (
    <Fragment>
      <div className="d-flex flex-column align-items-center text-center">
        <p>
          <BiMessageRoundedCheck size={56} color="#4ABA14" />
        </p>
        <h5 className="fw-bold">Thank you for responding</h5>
        <p className="mb-4">Your RSVP has been received</p>
        <button
          onClick={() => {
            closeTab();
          }}
          type="button"
          className="btn btn-sm btn-brown-primary rounded-pill px-4 fw-bold"
        >
          Close
        </button>
      </div>
    </Fragment>
  );
};

export default Done;
