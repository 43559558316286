import React, { Fragment } from "react";
import { Table } from "reactstrap";

const DetailsTab = ({ event }) => {
  return (
    <Fragment>
      <div className="card">
        <div className="card-body">
          <h5 style={{fontSize:'19px'}} className="text-nowrap fw-bold px-3 my-2">Event Details</h5>
          <Table className="border-white" responsive>
            <tbody>
              <tr style={{ fontSize: "14px" }} className="">
                <td className="px-3 text-nowrap">
                  <span>Event Name:</span>
                </td>
                <td className="px-3">
                  <span className="fw-bolder">{event.name}</span>
                </td>
              </tr>
              <tr style={{ fontSize: "14px" }} className="">
                <td className="px-3">
                  <span>Date:</span>
                </td>
                <td className="px-3">
                  <div className="d-flex align-items-center gap-4">
                    <span className="fw-bolder">{event.date}</span>
                    <span>
                      <span>Time:</span>{" "}
                      <span className="fw-bolder">
                        {event.start_time}:{event.end_time}
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
              <tr style={{ fontSize: "14px" }} className="">
                <td className="px-3" style={{width:'10px'}}>
                  <span>Venue:</span>
                </td>
                <td className="px-3">
                  <span className="fw-bolder">{event.venue}</span>
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: "14px" }} className="px-3 text-nowrap">
                  <span>RSVP Deadline:</span>
                </td>
                <td style={{ fontSize: "14px" }} className="px-3">
                  <span className="fw-bolder">{event.deadline}</span>
                </td>
              </tr>
              <tr className="">
                <td style={{ fontSize: "14px" }} className="px-3">
                  <span>Description:</span>
                </td>
                <td style={{ fontSize: "14px" }} className="px-3">
                  <span className="fw-bolder">{event.description}</span>
                </td>
              </tr>
              <tr className="">
                <td style={{ fontSize: "14px" }} className="px-3 text-nowrap">
                  <span>Invite Note:</span>
                </td>
                <td style={{ fontSize: "14px" }} className="px-3">
                  <span className="fw-bolder">{event.note}</span>
                </td>
              </tr>
              {/* <tr className="">
                                <td className="px-3 text-nowrap"><span>Invite Colour:</span></td>
                                <td className="px-3">
                                    <div className="input-group" style={{ width: 200 }}>
                                        <input type="color" className="form-control form-control-sm form-control-color" id="inputColour" defaultValue={event.colour} placeholder={event.colour} />
                                        <input type="text" className="form-control form-control-sm" id="inputColour" defaultValue={event.colour} />
                                    </div>
                                </td>
                            </tr> */}
            </tbody>
          </Table>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailsTab;
