import React, { Fragment, useState } from "react";
import { TbFileExport } from "react-icons/tb";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import DonutChart from "../../../../components/DonutChart";
import { api } from "../../../../service/api";

const FeedbackTab = ({ responses, preferences = [], id }) => {

  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [preferenceData, setPreferenceData] = useState({
    title: "",
    description: "",
    options: [],
    event_id: id
  });

  const addPreference = () => {
    setLoading(true)
    if (validationForm()) {
      if (editIndex !== -1) {
        console.log("updatePreference", preferenceData)
        api
          .post("/events/updatePreference", preferenceData)
          .then((response) => {
            console.log('Response', response.data.preference);
            preferences[editIndex] = response.data.preference;
            setEditIndex(-1);
            cleanForm();
          })
          .catch((error) => {
            console.error(error.response.data);
          })
          .finally(() => setLoading(false));
      } else {
        api
          .post("/events/addPreference", preferenceData)
          .then((response) => {
            console.log('addPreference', response.data);
            preferences.push(response.data.preference)
            cleanForm();
          })
          .catch((error) => {
            console.error(error.response.data);
          })
          .finally(() => setLoading(false));
      }
    } else {
      setLoading(false)
    }
  };

  const addOption = () => {
    setPreferenceData({
      ...preferenceData,
      options: [...preferenceData.options, { name: "" }],
    });
  };

  const removeOption = (index) => {
    const updatedOptions = [...preferenceData.options];
    updatedOptions.splice(index, 1);
    setPreferenceData({
      ...preferenceData,
      options: updatedOptions,
    });
  };

  const editPreference = (index) => {
    console.log(index)
    setEditIndex(index);
    setPreferenceData(preferences[index]);
    toggle();
  };

  const handleCancel = () => {
    cleanForm();
  };

  const cleanForm = () => {
    setPreferenceData({
      title: "",
      description: "",
      options: [],
    });
    toggle();
  };

  const validationForm = () => {
    if (preferenceData.title !== "" && preferenceData.description !== "") {
      return true;
    } else {
      alert("Please fill in all fields!");
      return false;
    }
  };

  return (
    <Fragment>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center bg-white">
          <h5
            style={{ fontSize: "18px" }}
            className="text-nowrap fw-bold px-3 my-2"
          >
            Summary
          </h5>
          <button
            type="button"
            className="btn btn-sm btn-white text-blue-primary fw-bolder"
          >
            <TbFileExport size={16} /> Export PDF
          </button>
        </div>
        <div className="card-body px-0">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-12 col-md-8 px-4">
              <h6 style={{ fontSize: "15px", fontWeight: "700" }}>
                Attending Responses
              </h6>
              <DonutChart
                data={[responses.yes, responses.no, responses.pending]}
                total={responses.yes + responses.no + responses.pending}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end border-top border-bottom p-2">
            <button
              onClick={toggle}
              className="btn btn-sm btn-white text-brown-primary d-flex align-items-center"
            >
              <AiOutlinePlus size={18} />
              <span className="ms-1" style={{ fontWeight: 600 }}>
                Add Preference
              </span>
            </button>
          </div>
          {preferences.map((preference, index) => {
            let pos = index + 1;
            return (
            <div
              className="d-flex flex-wrap border-bottom p-4 justify-content-between"
              key={index}
            >
              <div className="col-12">
                <button
                  className="btn btn-sm btn-white text-blue-light d-flex align-items-center"
                  onClick={() => editPreference(index)}
                >
                  <BiPencil size={16} />
                  <span className="ms-1" style={{ fontWeight: 600 }}>
                    Edit
                  </span>
                </button>
              </div>
              <div className="col-12 col-md-4">
                <h6 style={{ fontSize: "15px" }} className="fw-bolder">
                  Preference {pos}: {preference.title}
                </h6>
                <p style={{ fontSize: "12px" }} className="text-muted">
                  {preference.description}
                </p>
              </div>
              <div className="col-12 col-md-8 px-4">
                <div className="d-flex flex-wrap justify-content-between">
                  {preference.options.map((option, i) => (
                    <li
                      style={{ fontSize: "14px" }}
                      key={i}
                      className="text-nowrap w-50"
                    >
                      {option.name}
                    </li>
                  ))}
                </div>
              </div>
            </div>
            )}
          )}
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h5 className="text-center p-3">
            {editIndex !== -1 ? "Update" : "Add"} Preference
          </h5>
          <div className="row mb-2">
            <div className="col-12">
              <label className="form-label" htmlFor="inputTitle">
                Title
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="title"
                  id="inputTitle"
                  placeholder=""
                  value={preferenceData.title}
                  onChange={(e) =>
                    setPreferenceData({
                      ...preferenceData,
                      title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12">
              <label className="form-label" htmlFor="inputDescription">
                Description
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="description"
                  id="inputDescription"
                  placeholder=""
                  value={preferenceData.description}
                  onChange={(e) =>
                    setPreferenceData({
                      ...preferenceData,
                      description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {preferenceData.options.map((option, index) => (
            <div className="row mb-2" key={index}>
              <div className="col-12">
                <label className="form-label" htmlFor={`inputOption${index}`}>
                  Option {index + 1}
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id={`inputOption${index}`}
                    placeholder=""
                    value={option.name}
                    onChange={(e) => {
                      const updatedOptions = [...preferenceData.options];
                      updatedOptions[index] = { name: e.target.value };
                      setPreferenceData({
                        ...preferenceData,
                        options: updatedOptions,
                      });
                    }}
                  />
                  <button
                    className="btn btn-white btn-sm"
                    onClick={() => removeOption(index)}
                  >
                    <AiOutlineClose size={16} />
                  </button>
                </div>
              </div>
            </div>
          ))}
          <button
            onClick={addOption}
            className="btn btn-sm btn-white text-brown-primary d-flex align-items-center"
          >
            <AiOutlinePlus size={18} />
            <span className="ms-1" style={{ fontWeight: 600 }}>
              Add Option
            </span>
          </button>
        </ModalBody>
        <ModalFooter className="border-0">
          <Button
            color="secondary"
            className="btn btn-sm rounded-pill px-4"
            size="sm"
            outline
            onClick={handleCancel}
          >
            Cancel
          </Button>{" "}
          {loading ?
            <Button
              color="primary"
              className="btn btn-sm bg-blue-primary rounded-pill px-4"
              size="sm"
              disabled
            >
              <Spinner
                as="span"
                variant="white"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="grow"
              />
            </Button> :
            <Button
              type="button"
              color="primary"
              className="btn btn-sm bg-blue-primary rounded-pill px-4"
              size="sm"
              onClick={addPreference}
            >
              Confirm
            </Button>
          }
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default FeedbackTab;
