import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { api } from "../../../../service/api";
import Guests from "./Tabs/Guests";
import Preferences from "./Tabs/Preferences";
import "./index.css";

const GuestsPreferences = () => {
    const [tab, setTab] = useState(1);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const toggle = () => setModal(!modal);
    const [event, setEvent] = useState(null)
    // const { id } = useParams();
    const id = 1;
    useEffect(() => {
        loadEvent();
    }, [id]);

    const loadEvent = async () => {
        if (id) {
            try {
                const response = await api.get(`/events/${id}`);
                console.log("Resp - Event Mobile", response.data)
                setEvent(response.data.event);
            } catch (error) {
                console.error(error.response.data)
            }
        }
    };

    return (
        <>
            <div style={{ height: "100vh" }}>
                < ul className="nav d-flex bg-white pt-2 mb-4 border-bottom" >
                    <li className="w-50 px-3"><button onClick={() => setTab(1)} className={tab === 1 ? "w-100 btn btn-sm itemTab activeTab" : "w-100 btn btn-sm itemTab"}>Scans</button></li>
                    <li className="w-50 px-3"><button onClick={() => setTab(2)} className={tab === 2 ? "w-100 btn btn-sm itemTab activeTab" : "w-100 btn btn-sm itemTab"}>Preferences</button></li>
                </ul >
                {
                    event ?
                        <>
                            <div style={{ marginBottom: "5rem" }}>
                                {tab === 1 && <Guests id={event.id} />}
                                {tab === 2 && <Preferences preferences={event.preferences} />}
                            </div>
                        </> : <h1 className="text-center">
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <Spinner
                                color="primary"
                                type="grow"
                            >
                                Loading...
                            </Spinner>
                        </h1>
                }
            </div>
        </>
    );
}

export default GuestsPreferences;