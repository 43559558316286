import React, { Fragment } from "react";
import { GrCircleInformation } from "react-icons/gr";
const GuestDetails = ({ guest }) => {
    return (
        <Fragment>
            <div className="text-center mb-2 text-blue-light" >
                <GrCircleInformation size={18} color="#1F59A4" />
                <h5 className="fw-bolder mt-2">Guest Details</h5>
            </div>
            <div className="px-3 mb-2">
                <p className="mb-0">Name</p>
                <h6 className="mb-0 fw-bold">{guest.name}</h6>
            </div>
            <div className="px-3 mb-2">
                <p className="mb-0">Title</p>
                <h6 className="mb-0 fw-bold">{guest.title}</h6>
            </div>
            <div className="px-3 mb-2">
                <p className="mb-0">Company</p>
                <h6 className="mb-0 fw-bold">{guest.company}</h6>
            </div>
            {guest.options.map((item, index) => <div key={index} className="px-3 mb-2">
                <p className="mb-0">{item.preference.title}</p>
                <h6 className="mb-0 fw-bold">{item.name}</h6>
            </div>)}
            <div className="px-3 mb-2">
                <p className="mb-0">Sponsor Option</p>
                <h6 className="mb-0 fw-bold">{'PLATINUM (00)'}</h6>
            </div>
        </Fragment>
    );
}

export default GuestDetails;