import React, { Fragment } from "react";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Pagination = ({ pageCount = 0, handlePageClick }) => {
  return (
    <Fragment>
      <ReactPaginate
        previousLabel={<IoIosArrowBack />}
        nextLabel={<IoIosArrowForward />}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName={'pagination react-paginate separated-pagination'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        activeClassName={'active'}
        previousLinkClassName={'page-link'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        disableInitialCallback={true}
        disablePageChangeOnKey={true}
        disablePageChangeOnSelect={true}
        hrefBuilder={(pageIndex) => '#'}
        renderLink={(props) => {
          if (props.pageNumber === 0) {
            return <li className="page-item disabled"><a className="page-link">{'<'}{'<'}</a></li>;
          } else if (props.pageNumber === props.pageCount - 1) {
            return <li className="page-item disabled"><a className="page-link">{'>'}{'>'}</a></li>;
          } else {
            return (
              <li className={props.pageNumber === props.selected ? 'page-item active' : 'page-item'}>
                <a {...props} className="page-link">
                  {props.pageNumber + 1}
                </a>
              </li>
            );
          }
        }}
      />
    </Fragment>
  );
}

export default Pagination;