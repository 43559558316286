import React, { useState, useEffect } from "react";
import scanok from "../../../assets/mobile/scanok.svg";
import scanbad2 from "../../../assets/mobile/scanbad2.svg";
import scanbad from "../../../assets/mobile/scanbad.svg";
import { AiOutlineClose } from "react-icons/ai";
import QrReader from "react-qr-scanner";
import "../../../assets/GlobalStyle.css";
import "./QRCodeScanner.css";
import { api } from "../../../service/api";

const ScanQRCode = () => {
    const [result, setResult] = useState(null);
    const [display, setDisplay] = useState(1);
    const [scanning, setScanning] = useState(true);
    const [guest, setGuest] = useState(null);

    const handleScan = (data) => {
        if (data) {
            alert(data)
            console.log(data)
            setResult(data);
            // loadGuest(data);
            setScanning(false);
        } else {

        }
        console.log("result", data)
    };

    const handleError = (err) => {
        console.error('err', err);
        setScanning(false);
    };

    // const loadGuest = async (id) => {
    //     try {
    //         const response = await api.get(`/scanner/${id}`);
    //         console.log("resp - scanner", response.data)
    //         setGuest(response.data.guest);
    //     } catch (error) {
    //         console.error("error - scanner", error.response.data)
    //     }
    // };

    const closeResults = () => {
        console.log("Close")
        startScanning();
    }

    const startScanning = async () => {
        setScanning(true);
        setDisplay(1);
        const timeout = setTimeout(() => {
            if (!result) {
                setDisplay(3);
                setScanning(false);
            }
        }, 10000);

        return () => {
            clearTimeout(timeout);
            setScanning(false);
        };
    }

    useEffect(() => {
        startScanning();
    }, []);

    return (
        <div style={{ backgroundColor: "#FFFFFF" }}>
            <div className="container d-flex flex-column justify-content-center align-items-center gap-5" style={{ height: "100vh" }}>
                <div className="w-100">
                    {display === 1 && <div id="scanner" className="" style={{ maxWidth: "360px", margin: "0 auto" }}>
                        {scanning && <div className="scanner"></div>}
                        <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '100%' }}
                        />
                        <h5 className="my-4 fw-bolder text-center">SCAN QR CODE</h5>
                        <p className="text-center mb-4 text-center">Hold the QR code inside the frame and press ‘scan’ to initiate the process.</p>
                    </div>}

                    {display === 2 && <div className="">
                        <div className="d-flex justify-content-end">
                            <button className="btn bg-transparent btn-sm" onClick={() => closeResults()}>
                                <AiOutlineClose color="black" size={18} />
                            </button>
                        </div>
                        <div className="text-center"><img src={scanok} alt="" /></div>
                        <h5 className="mb-0 fw-bolder text-center">Scan</h5>
                        <h5 className="mb-4 fw-bolder text-center">Successful</h5>
                        <div className="px-3 my-2">
                            <p className="mb-0">Guest Name</p>
                            <h6 className="mb-0 fw-bold">{guest.title} {guest.name}</h6>
                        </div>
                        <div className="px-3 mb-2">
                            <p className="mb-0">Company</p>
                            <h6 className="mb-0 fw-bold">{guest.company}</h6>
                        </div>
                        <div className="px-3 mb-2">
                            <p className="mb-0">Time Scanned</p>
                            <h6 className="mb-0 fw-bold">{guest.scanned_at}</h6>
                        </div>
                    </div>}

                    {display === 3 && <div className="">
                        <div className="d-flex justify-content-end">
                            <button className="btn bg-transparent btn-sm" onClick={() => closeResults()}>
                                <AiOutlineClose color="black" size={18} />
                            </button>
                        </div>
                        <div className="text-center"><img src={scanbad} alt="" /></div>
                        <h5 className="my-4 fw-bolder text-center">Scan Failed</h5>
                        <p className="text-center">Please ensure camera is focused before scanning.</p>
                    </div>}

                    {display === 4 && <div className="">
                        <div className="d-flex justify-content-end">
                            <button className="btn bg-transparent btn-sm" onClick={() => closeResults()}>
                                <AiOutlineClose color="black" size={18} />
                            </button>
                        </div>
                        <div className="text-center"><img src={scanbad2} alt="" /></div>
                        <h5 className="mb-0 fw-bolder text-center">Scan Failed</h5>
                        <p className="text-center">Guest already scanned in.</p>
                        <div className="px-3 my-2">
                            <p className="mb-0">Guest Name</p>
                            <h6 className="mb-0 fw-bold">{'guest.title'} {'guest.name'}</h6>
                        </div>
                        <div className="px-3 mb-2">
                            <p className="mb-0">Company</p>
                            <h6 className="mb-0 fw-bold">{'guest.company'}</h6>
                        </div>
                        <div className="px-3 mb-2">
                            <p className="mb-0">Time Scanned</p>
                            <h6 className="mb-0 fw-bold">{'30-11-2023 11:48:09'}</h6>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default ScanQRCode;
