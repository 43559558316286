import React, { Fragment, useState } from "react";

const Response = ({ formData, handleChange, handleSubmit, setStep }) => {
    const handleResponseChange = (response) => {
        handleChange({ target: { name: "response", value: response } });
    };

    const handleContinue = () => {
        if (formData.response === "Yes") {
            setStep(4);
        } else if (formData.response === "No") {
            const advice = "Are you sure you don't want to participate in the event?";
            if (window.confirm(advice) === true) {
                handleSubmit();
            }
        } else {
            alert("Please select an answer (Yes/No).");
        }
    };

    return (
        <Fragment>
            <div className="">
                <h5 className="fw-bold mb-4 text-blue-primary">Welcome ({formData.guest.title} {formData.guest.name})</h5>
                <p className="text-muted mb-4">Thank you acknowledging our invite, please let us know if you will be attending this upcoming event.</p>
                <div className="d-flex mb-4">
                    <div className="form-check form-check-inline border px-4 py-2 rounded w-50 d-flex flex-row-reverse justify-content-between">
                        <input className="form-check-input response-invite" type="radio" name="inputResponse" id="optionYes" value="Yes" onChange={() => handleResponseChange("Yes")} />
                        <label className="form-check-label" htmlFor="optionYes"><span className="response-labels">Yes</span></label>
                    </div>
                    <div className="form-check form-check-inline border px-4 py-2 rounded w-50 d-flex flex-row-reverse justify-content-between">
                        <input className="form-check-input response-invite" type="radio" name="inputResponse" id="optionNo" value="No" onChange={() => handleResponseChange("No")} />
                        <label className="form-check-label" htmlFor="optionNo"><span className="response-labels">No</span></label>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-sm btn-brown-primary rounded-pill px-4 fw-bold" onClick={handleContinue} disabled={formData.response === ""}>Continue</button>
                </div>
            </div>
        </Fragment>
    );
}

export default Response;