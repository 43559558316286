import React, { Fragment } from "react";
import { FaRegSmile } from "react-icons/fa";

const BadResponse = () => {
    return (
        <Fragment>
            <div className="d-flex flex-column align-items-center text-center">
                <p><FaRegSmile size={56} color="#5F6368" /></p>
                <h5 className="fw-bold">No; I will not be attending</h5>                
                <p className="">Thank you for your feedback on our invitation.</p>
                <p className="mb-4">We hope to see you at our next event!</p>
                <button type="button" className="btn btn-sm btn-brown-primary rounded-pill px-4 fw-bold">Close</button>
            </div>
        </Fragment>
    );
}

export default BadResponse;