import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../service/auth";
import logo from "../../assets/logo.png";
import { MdLogout } from "react-icons/md";
import "./style.css";

const Header = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    const advice = "Are you sure you want to log out?";
    if (window.confirm(advice) === true) {
      logout(); 
      navigate('/');
    }
  }

  return (
    <div className="sticky-top bg-white py-3 border-bottom">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3 text-secondary">
          <Link to={"/home"}>
            <img src={logo} alt="Eswatini-Railways Logo" width={152} />
          </Link>
          <span style={{ fontWeight: 500, fontSize: "15px" }}>Events Portal</span>
        </div>
        <div>
          <button type='button' onClick={handleSignOut} className="btn btn-sm btn-white text-secondary">
            <MdLogout size={15} />
            <span className="ms-2" style={{ fontWeight: 500 }}>Log Out</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
