import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DonutChart = ({ data, total }) => {
  const options = {
    chart: {
      type: "pie",
      height: 300,
      marginRight: 0,
    },
    title: {
      text: "",
    },

    credits: { enabled: false },
    subtitle: {
      text: `${total}<br/>Total`,
      verticalAlign: "middle",
      style: {
        fontSize: "18px",
        fontWeight: "bolder",
      },
      floating: true,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        colors: ["#15D911", "#D1623F", "#AAA"],

        innerSize: "90%",
        allowPointSelect: true,
        cursor: "pointer",
        showInLegend: true,
        dataLabels: {
          enabled: false,
          distance: -20,
        },
        animation: {
          duration: 1000,
        },
        enableMouseTracking: true,
      },
    },
    legend: {
      labelFormatter: function () {
        return this.name + " (" + this.y + "%)";
      },
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: -100,
      y: 100,
    },
    series: [
      {
        name: "Respostas",
        data: [
          ["Yes", (data[0] / total) * 100],
          ["No", (data[1] / total) * 100],
          ["Pending", (data[2] / total) * 100],
        ],
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DonutChart;
