import { Fragment } from "react";
import Header from "./Header";
import "./index.css";

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <main className="container mt-4">{children}</main>
    </Fragment>
  );
};

export default Layout;
