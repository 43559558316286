import React, { Fragment, useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BiPencil } from "react-icons/bi";
import { BsSearch, BsTicket } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { Table } from "reactstrap";
import Pagination from "../../../../components/Pagination";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { api } from "../../../../service/api";

const GuestsTab = ({ guests, id }) => {
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [data, setData] = useState([]);
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * itemsPerPage;
  const selectedItems = data.filter((entry) =>
    selectedStatus === "All" ? true : entry.rsvp === selectedStatus
  );
  const currentItems = selectedItems.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(selectedItems.length / itemsPerPage);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  useEffect(() => {
    setData(guests);
  }, [guests]);

  const [showDetails, setShowDetails] = useState(data.map(() => false));

  const toggleDetails = (index) => {
    const newShowDetails = [...showDetails];
    newShowDetails[index] = !newShowDetails[index];
    setShowDetails(newShowDetails);
  };

  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [guestData, setGuestData] = useState({
    name: "",
    title: "",
    company: "",
    email: "",
    event_id: id
  });

  const addGuest = () => {
    setLoading(true)
    if (validationForm()) {
      if (editIndex !== -1) {
        console.log("Update", guestData)
        api
          .post("/events/updateGuest", guestData)
          .then((response) => {
            // console.log('Response', response.data.guest);
            guests[editIndex] = response.data.guest;
            setEditIndex(-1);
            cleanForm();
          })
          .catch((error) => {
            console.error(error.response.data);
          })
          .finally(() => setLoading(false));
      } else {
        api
          .post("/events/addGuest", guestData)
          .then((response) => {
            guests.push(response.data.guest)
            // console.log('Guests', data);
            cleanForm();
          })
          .catch((error) => {
            console.error(error.response.data);
          })
          .finally(() => setLoading(false));
      }
    } else {
      setLoading(false)
    }
  };

  const editGuest = (index) => {
    setEditIndex(index);
    const guest = data[index];
    setGuestData({
      id: guest.id,
      name: guest.name,
      title: guest.title,
      company: guest.company,
      email: guest.email,
    });
    toggle();
  };

  const handleCancel = () => {
    cleanForm();
  };

  const cleanForm = () => {
    setGuestData({
      name: "",
      title: "",
      company: "",
      email: "",
    });
    toggle();
  };

  const validationForm = () => {
    if (
      guestData.name !== "" &&
      guestData.title !== "" &&
      guestData.company !== "" &&
      guestData.email !== ""
    ) {
      return true;
    } else {
      alert("Please fill in all fields!");
      return false;
    }
  };

  return (
    <Fragment>
      <div className="row mb-2">
        <div className="col-12 col-md-4">
          <div className="d-flex align-items-center gap-2">
            <div className="input-group">
              <div className="input-group-text bg-white">
                <BsSearch size={18} />
              </div>
              <input
                type="search"
                className="form-control form-control-sm"
                id="inputDate"
                placeholder=""
              />
            </div>
            <button type="button" className="btn btn-sm bg-blue-primary">
              Search
            </button>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="d-flex justify-content-end">
            <button
              onClick={toggle}
              className="btn btn-sm btn-white text-brown-primary d-flex align-items-center"
            >
              <AiOutlinePlus size={18} />
              <span className="ms-1" style={{ fontWeight: 600 }}>
                Add Guest
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="card pb-0">
        <div className="card-header bg-white pb-0">
          <ul className="nav d-flex gap-4">
            <li>
              <button
                style={{ fontSize: "13px" }}
                onClick={() => setSelectedStatus("All")}
                className={
                  selectedStatus === "All"
                    ? "btn btn-sm itemTab activeTab"
                    : "btn btn-sm itemTab"
                }
              >
                All Guests
              </button>
            </li>
            <li>
              <button
                style={{ fontSize: "13px" }}
                onClick={() => setSelectedStatus("Yes")}
                className={
                  selectedStatus === "Yes"
                    ? "btn btn-sm itemTab activeTab"
                    : "btn btn-sm itemTab"
                }
              >
                Yes
              </button>
            </li>
            <li>
              <button
                style={{ fontSize: "13px" }}
                onClick={() => setSelectedStatus("No")}
                className={
                  selectedStatus === "No"
                    ? "btn btn-sm itemTab activeTab"
                    : "btn btn-sm itemTab"
                }
              >
                No
              </button>
            </li>
          </ul>
        </div>
        <Table className="border mb-0" responsive>
          <thead className="">
            <tr className="table-secondary">
              <td className="px-3 text-muted">
                <small style={{ fontSize: "14px" }}>Name</small>
              </td>
              <td className="px-3 text-muted">
                <small style={{ fontSize: "14px" }}>Email</small>
              </td>
              <td className="px-3 text-muted">
                <small style={{ fontSize: "14px" }}>Response</small>
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody className="bg-white">
            {currentItems.map((guest, index) => (
              <React.Fragment key={index}>
                <tr className="">
                  <td style={{ padding: "10px 16px" }}>
                    <span style={{ fontSize: "14px" }}>{guest.name}</span>
                  </td>
                  <td style={{ padding: "10px 0px" }}>
                    <span style={{ fontSize: "14px" }}>{guest.email}</span>
                  </td>
                  <td style={{ padding: "10px 0px" }}>
                    <Status value={guest.rsvp} />
                  </td>
                  <td className="d-flex align-items-center gap-2">
                    <button
                      style={{ fontSize: "14px" }}
                      className="btn btn-sm btn-white text-blue-light"
                      onClick={() => toggleDetails(index)}
                    >
                      {showDetails[index] ? (
                        <IoIosArrowUp size={14} />
                      ) : (
                        <IoIosArrowDown size={14} />
                      )}
                      <span style={{ fontWeight: 600 }}>Details</span>
                    </button>
                    <button
                      className="btn btn-sm btn-white text-blue-light d-flex align-items-center"
                      onClick={() => editGuest(index)}
                    >
                      <BiPencil size={16} />
                      <span className="ms-1" style={{ fontWeight: 600 }}>
                        Edit
                      </span>
                    </button>
                  </td>
                </tr>
                {showDetails[index] && (
                  <tr>
                    <td colSpan={4}>
                      <Details guest={guest} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end p-4 pb-2">
          <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h5 className="text-center p-3">
            {editIndex !== -1 ? "Update" : "Add"} Guest
          </h5>
          <div className="row">
            <div className="col-12 mb-2">
              <label className="form-label" htmlFor="inputName">
                Name
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="name"
                  value={guestData.name}
                  onChange={(e) =>
                    setGuestData({ ...guestData, name: e.target.value })
                  }
                  id="inputName"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <label className="form-label" htmlFor="inputTitle">
                Title
              </label>
              <select
                className="form-select form-select-sm"
                name="title"
                defaultValue={guestData.title}
                onChange={(e) =>
                  setGuestData({ ...guestData, title: e.target.value })
                }
                id="inputTitle"
              >
                <option value={guestData.title}>{guestData.title}</option>

                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
              </select>
            </div>
            <div className="col-12 col-md-6 mb-2">
              <label className="form-label" htmlFor="inputCompany">
                Company
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="company"
                value={guestData.company}
                onChange={(e) =>
                  setGuestData({ ...guestData, company: e.target.value })
                }
                id="inputCompany"
                placeholder=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2">
              <label className="form-label" htmlFor="inputEmail">
                Email
              </label>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control form-control-sm"
                  name="email"
                  value={guestData.email}
                  onChange={(e) =>
                    setGuestData({ ...guestData, email: e.target.value })
                  }
                  id="inputEmail"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="border-0">
          <Button
            color="secondary"
            className="btn btn-sm rounded-pill px-4 "
            size="sm"
            outline
            type="reset"
            onClick={handleCancel}
          >
            Cancel
          </Button>{" "}
          {loading ?
            <Button
              color="primary"
              className="btn btn-sm bg-blue-primary rounded-pill px-4"
              size="sm"
              disabled
            >
              <Spinner
                as="span"
                variant="white"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="grow"
              />
            </Button> :
            <Button
              type="button"
              color="primary"
              className="btn btn-sm bg-blue-primary rounded-pill px-4"
              size="sm"
              onClick={addGuest}
            >
              Confirm
            </Button>
          }

        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const Status = ({ value }) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <div
        className="rounded-circle"
        style={{
          width: 16,
          height: 16,
          backgroundColor:
            value == "Yes" ? "#2AD10F" : value == "No" ? "#D1623F" : "#AAA",
        }}
      ></div>
      <span>{value ? value : "Pending"}</span>
    </div>
  );
};
const Details = ({ guest }) => {
  return (
    <div style={{ width: '99%' }} className="d-flex justify-content-between align-items-center">
      <div className="col-12 col-md-4">
        <div className="mb-5 px-3">
          <h5
            style={{ color: "#1F59A4", fontSize: "13px" }}
            className="form-label"
          >
            <FiMail size={16} />
            <span className="ms-2">Resend Invite</span>
          </h5>
          {/* <h5
            style={{ color: "#1F59A4", fontSize: "13px" }}
            className="form-label"
          >
            <BiPencil size={16} />
            <span className="ms-2">Edit Response</span>
          </h5> */}
          <h5
            style={{ color: "#1F59A4", fontSize: "13px" }}
            className="form-label"
          >
            <BsTicket size={16} />
            <span className="ms-2">Send Ticket</span>
          </h5>
        </div>
        <small className="text-muted">
          modified:{" "}
          <i>
            {new Date(guest.updated_at).getDay()}
            {"/"}
            {new Date(guest.updated_at).getMonth()}
            {"/"}
            {new Date(guest.updated_at).getFullYear()}
          </i>
        </small>
      </div>
      <div className="col-12 col-md-8">
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <small style={{ fontSize: "13px" }} className="">
              Title
            </small>
            <p style={{ fontSize: "15px" }} className="form-label mb-0">
              {guest.title}
            </p>
          </div>
          <div className="col-12 col-md-6 mb-3">
            <small style={{ fontSize: "13px" }} className="">
              Company
            </small>
            <p style={{ fontSize: "15px" }} className="form-label mb-0">
              Company
            </p>
          </div>
          {guest.options.map((option, index) => (
            <div className="col-12 col-md-6 mb-3" key={index}>
              <small style={{ fontSize: "13px" }} className="">{option.preference.title}</small>
              <p style={{ fontSize: "15px" }} className="form-label mb-0">{option.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default GuestsTab;
