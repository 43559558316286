import React, { Fragment, useState } from "react";
import { TbCalendarDue } from "react-icons/tb";
import { MdOutlineImage } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";

const EventDeatis = ({ formData, handleChange, nextStep, loading, setLoading }) => {
    const [previewerImage, setPreviewerImage] = useState(null);
    const [imgSize, setImgSize] = useState("");

    const validationForm = () => {
        if (formData.name !== '' || formData.date !== '' || formData.start_time !== '' || formData.end_time !== '' || formData.description !== '' || formData.venue !== '' || formData.deadline !== '' || formData.description !== '' || formData.note !== '' || formData.colour !== '') {
            return true;
        } else {
            alert('Please fill in all fields!');
            return false;
        }
    }

    const handleNext = () => {
        if (validationForm()) {
            nextStep();
        }
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImgSize("")
            handleChange({ target: { name: "image_logo", value: file } });
            const KB = Math.round(file.size / 1024);
            if(KB < 1024){
                setImgSize(`${KB}KB`);
            }else{
                const MB = Math.round(KB / 1024);
                setImgSize(`${MB}MB`);
            }
            setPreviewerImage(URL.createObjectURL(file));
        }
    };
    const clearImage = () => {
        handleChange({ target: { name: "image_logo", value: "" } });
        setPreviewerImage(null);
        setImgSize("");
    };

    return (
        <Fragment>
            <div className="card-body">
                <h6 style={{marginBottom:'26px', fontWeight:'600'}}>Event Details</h6>
                <div className="row">
                    <div className="col-12 col-md-6 mb-2">
                        <label className="form-label" htmlFor="inputName">Event Name</label>
                        <div className="input-group">
                            <input type="text" className="form-control form-control-sm" name="name" value={formData.name} onChange={handleChange} id="inputName" placeholder="" />
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb-2">
                        <label className="form-label" htmlFor="inputDate">Date</label>
                        <div className="input-group">
                            <div className="input-group-text bg-white"><TbCalendarDue size={18} /></div>
                            <input type="date" className="form-control form-control-sm" name="date" value={formData.date} onChange={handleChange} id="inputDate" placeholder="DD/MM/YYYY" />
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb-2">
                        <label className="form-label" htmlFor="inputTime">Time</label>
                        <div className="input-group">
                            <input type="time" className="form-control form-control-sm" name="start_time" value={formData.start_time} onChange={handleChange} id="inputTimeStart" placeholder="Start" />
                            <input type="time" className="form-control form-control-sm" name="end_time" value={formData.end_time} onChange={handleChange} id="inputTimeEnd" placeholder="End" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mb-2">
                        <label className="form-label" htmlFor="inputVenue">Venue</label>
                        <div className="input-group">
                            <input type="text" className="form-control form-control-sm" name="venue" value={formData.venue} onChange={handleChange} id="inputVenue" placeholder="" />
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb-2">
                        <label className="form-label" htmlFor="inputRSVP">RSVP Deadline</label>
                        <div className="input-group">
                            <div className="input-group-text bg-white"><TbCalendarDue size={18} /></div>
                            <input type="date" className="form-control form-control-sm" name="deadline" value={formData.deadline} onChange={handleChange} id="inputRSVP" placeholder="Before" />
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12">
                        <label className="form-label" htmlFor="inputDescription">Description</label>
                        <div className="input-group">
                            <input type="text" className="form-control form-control-sm" name="description" value={formData.description} onChange={handleChange} id="inputDescription" placeholder="We would like to invite you to...." />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2">
                        <label className="form-label" htmlFor="inputInvite">Invite Note</label>
                        <div className="input-group">
                            <input type="text" className="form-control form-control-sm" name="note" value={formData.note} onChange={handleChange} id="inputInvite" placeholder="Please Note:" />
                        </div>
                    </div>
                </div>
                {/* <div className="row align-items-center">
                    <div className="col-12 col-md-3 mb-2">
                        <label className="form-label" htmlFor="inputColour">Invite Colour</label>
                        <div className="input-group">
                            <input type="color" className="form-control form-control-sm form-control-color" name="colour" value={formData.colour} onChange={handleChange} id="inputColour" placeholder="000000" />
                            <input type="text" className="form-control form-control-sm" name="color" id="inputColour" defaultValue={formData.colour} />
                        </div>
                    </div>
                    <div className="col-12 col-md-9 mb-2">
                        <p className="mb-0 mt-2 text-md-end text-muted"><small>This colour will be the main background colour for this event.</small></p>
                    </div>
                </div> */}
                {/* <div className="row align-items-center">
                    <div className="col-12 col-md-4 mb-2">
                        <label className="form-label">Invite Logo</label>
                        <div className="input-group border rounded align-items-center justify-content-between">
                            <label htmlFor="inputLogo" className="mb-0 btn btn-white btn-sm" ><MdOutlineImage size={16} /></label>
                            <input
                                type="file"
                                id="inputLogo"
                                accept="image/*"
                                onChange={(e) => handleImageUpload(e)}
                                className="d-none"
                            />
                            <label className="form-label mb-0 text-muted fw-normal">{imgSize !== '' ? <>{imgSize}</>:'File Size'}</label>
                            <button className="btn btn-white btn-sm" onClick={clearImage}><AiOutlineClose size={16} /></button>
                        </div>
                        <div className="mt-2 mx-auto" style={{width: 200, height: 200}}>
                            <img src={previewerImage} alt="Visualizar Imagem" className="img-fluid" style={{ maxWidth: '100%' }} />
                        </div>
                    </div>
                    <div className="col-12 col-md-8 mb-2">
                        <p className="mb-0 mt-2 text-md-end text-muted"><small>This image will be your invite header along with the event name.</small></p>
                    </div>
                </div> */}
            </div>
            <div className="card-footer d-flex justify-content-between align-items-center bg-white py-3">
                <button className="btn btn-sm btn-outline-dark rounded-pill px-4 ms-2">Cancel</button>
                <div className="d-flex">
                    <button disabled={true} className="btn btn-sm btn-outline-warning rounded-pill px-4 ms-2">Back</button>
                    <button onClick={handleNext} className="btn btn-sm bg-blue-primary rounded-pill px-4 ms-2">Continue</button>
                </div>
            </div>
        </Fragment>
    );
}

export default EventDeatis;