import React, { Fragment, useEffect, useState } from "react";
import logo from "../../../assets/logo.png";
import Welcome from "./Steps/Welcome";
import Authentication from "./Steps/Authenticate";
import Response from "./Steps/Response";
import Sponsor from "./Steps/Sponsor";
import "./index.css";
import Preferences from "./Steps/Preferences";
import Done from "./Steps/Done";
import BadResponse from "./Steps/BadResponse";
import Canceled from "./Steps/Canceled";
import { api } from "../../../service/api";

const Invitee = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [event, setEvent] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    date: "",
    start_time: "",
    end_time: "",
    venue: "",
    deadline: "",
    note: "",
    guest: null,
    response: "",
    preferences: [],
    sponsor: "",
  });

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const email = urlParams.get("email");

  useEffect(() => {
    loadEvent();
  }, [id, email]);

  const loadEvent = async () => {
    if (id) {
      try {
        const response = await api.get(`/events/${id}`);
        console.log("resp - Invitee", response.data);
        setEvent(response.data.event);
        if (response.data.event.canceled_at) {
          setStep(8);
        } else {
          const guestWithEmail = response.data.event.guests.find(
            (guest) => guest.email === email
          );
          setFormData({
            ...formData,
            id: id,
            name: response.data.event.name,
            date: response.data.event.date,
            start_time: response.data.event.start_time,
            end_time: response.data.event.end_time,
            venue: response.data.event.venue,
            deadline: response.data.event.deadline,
            note: response.data.event.note,
            guest: guestWithEmail,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const ResponseGuest = {
      guest_id: formData.guest.id,
      response: formData.response,
      preferences: formData.preferences,
      sponsor: formData.sponsor,
    };

    api
      .post("/events/response", ResponseGuest)
      .then((response) => {
        if (response.data.status) {
          setStep(6);
        } else {
          setStep(7);
        }
      })
      .catch((error) => {
        console.error(error.response.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <div
        className="pt-2"
        style={{ backgroundColor: "#001E64", height: "100vh" }}
      >
        <div
          className="card mx-auto pb-4"
          style={{ width: "100%", maxWidth: 610 }}
        >
          <div className="card-header text-center bg-white">
            <img src={logo} alt="Eswatini-Railways Logo" width={162} />
            <h5 className="card-title fw-bolder my-2">Invitational Golf day</h5>
          </div>
          {event ? (
            <div className="card-body">
              {step === 1 && (
                <Welcome
                  formData={formData}
                  handleChange={handleChange}
                  nextStep={nextStep}
                />
              )}
              {step === 2 && (
                <Authentication
                  formData={formData}
                  handleChange={handleChange}
                  nextStep={nextStep}
                />
              )}
              {step === 3 && (
                <Response
                  formData={formData}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  setStep={setStep}
                />
              )}
              {step === 4 && (
                <Preferences
                  formData={formData}
                  handleChange={handleChange}
                  loading={loading}
                  handleSubmit={handleSubmit}
                  nextStep={nextStep}
                  preferences={event.preferences}
                />
              )}
              {/* {step === 5 && (
                            <Sponsor formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} setLoading={setLoading} sponsors={event.sponsors} />
                        )} */}
              {step === 6 && <Done />}
              {step === 7 && <BadResponse />}
              {step === 8 && <Canceled name={event.name} />}
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Invitee;
