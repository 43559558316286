import React, { Fragment, useState } from "react";
import EventDeatis from "./EventDetails";
import GuestList from "./GuestList";
import EventPreferences from "./EventPreferences";
import SponsorOption from "./SponsorOption";
import Finished from "./Finished";
import { api } from "../../../service/api";

const CreateEvent = () => {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState({});
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    date: "",
    start_time: "",
    end_time: "",
    venue: "",
    deadline: "",
    description: "",
    note: "",
    colour: "",
    image_logo: "",
    location: "",
    guests: [
      {
        name: "",
        title: "",
        company: "",
        email: "",
      },
    ],
    preferences: [],
    sponsors: [],
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    api
      .post("events", formData, options)
      .then((response) => {
        setEvent(response.data.event);
        setStep(5);
      })
      .catch((error) => {
        console.error(error.response.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      {step === 5 ? (
        <Finished event={event} />
      ) : (
        <div className="card">
          <div className="card-header text-center bg-white">
            <h5 className="card-title mb-0 fw-bold">Create Event</h5>
          </div>
          {step === 1 && (
            <EventDeatis
              formData={formData}
              handleChange={handleChange}
              nextStep={nextStep}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {step === 2 && (
            <GuestList
              formData={formData}
              handleChange={handleChange}
              prevStep={prevStep}
              nextStep={nextStep}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {step === 3 && (
            <EventPreferences
              formData={formData}
              handleChange={handleChange}
              prevStep={prevStep}
              handleSubmit={handleSubmit}
              nextStep={nextStep}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {/* {step === 4 && (
            <SponsorOption
              formData={formData}
              handleChange={handleChange}
              prevStep={prevStep}
              handleSubmit={handleSubmit}
              loading={loading}
              setLoading={setLoading}
            />
          )} */}
        </div>
      )}
    </Fragment>
  );
};

export default CreateEvent;
