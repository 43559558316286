import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import "../../../assets/GlobalStyle.css";
import { Spinner } from "reactstrap";
import "./index.css";
import { api, baseURL } from "../../../service/api";
import { login, saveUser } from "../../../service/auth";
import axios from "axios";

const GetStarted = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const { setAuthenticated, setUser } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        axios
          .get(baseURL + "/sanctum/csrf-cookie")
          .then(async (response) => {
            try {
              const response2 = await api.post(`/auth/login`, {
                email,
                password,
              });
              setUser(response2.data.data.user);
              login(response2.data.data.access_token);
              setAuthenticated(true);
              saveUser(response2.data.data.user);
              navigate("/mobile/scan/qrcode");
            } catch (error) {
              if (error.response) {
                if (error.response.status === 422) {
                  setErrors(error.response.data.errors);
                }
    
                setErrorMessage(error.response.data.message);
              } else {
                setErrorMessage("We are having connection problems!");
              }
            } finally {
              setIsLoading(false);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

    return (
        <div className="bg-white d-flex flex-column justify-content-center align-items-center px-3" style={{ height: '100vh'}}>
            <form autoComplete="off" onSubmit={handleSubmit} className="w-100">
                <h4 className="text-blue-primary fw-bold">Login</h4>
                <div className="form-group my-4">
                    <label htmlFor="inputEmail" className="form-label fw-normal">Username</label>
                    <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        placeholder=""
                        value={email}
                        onChange={({ target }) => setEmail(target.value)}
                    />
                    {errors.email && <p className="text-danger">{errors.email}</p>}
                </div>
                <div className="form-group my-4">
                    <label htmlFor="inputPassword" className="form-label fw-normal">Password</label>
                    <div className="input-group">
                        <input
                            type="text"
                            value={password}
                            className="form-control"
                            placeholder=""
                            onChange={({ target }) => setPassword(target.value)}
                        />
                    </div>
                    {errors.password && <p className="text-danger">{errors.password}</p>}
                </div>
                <p className="text-end fw-bolder"><Link className="text-blue-primary">Forgot Password?</Link></p>
                <div className="form-group my-4">
                    {isLoading ?
                        <button type="button" className="btn bg-blue-primary w-100" disabled={true}>
                            <Spinner
                                as="span"
                                variant="white"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="grow"
                            />
                        </button> :
                        <button type="submit" className="btn bg-blue-primary w-100">Get Started</button>
                    }
                </div>
            </form>
        </div>
    );
};

export default GetStarted;
