import React, { Fragment } from "react";
import { BsSearch } from "react-icons/bs";
const Preferences = ({ preferences = [] }) => {
    console.log(preferences)
    return (
        <Fragment>
            <div className="row mb-4 px-3">
                <div className="col-12">
                    <div className="input-group">
                        <div className="input-group-text bg-white"><BsSearch size={18} /></div>
                        <input type="search" className="form-control form-control-sm" id="inputDate" placeholder="" />
                    </div>
                </div>
            </div>
            {preferences.map((item, index) => <div key={index} className="border-bottom mb-2">
                {item.options.map((option, key) => {
                    <div className="px-3" key={key}>
                        <p className="mb-0">{item.title}</p>
                        <div className="d-flex justify-content-between">
                            <h6 className="mb-0 fw-bold">{option.name}</h6>
                        </div>
                    </div>
                })}
            </div>)}
        </Fragment>
    );
}

export default Preferences;