import React, { Fragment, useState } from "react";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

const EventPreferences = ({
  formData,
  handleChange,
  prevStep,
  nextStep,
  handleSubmit,
  loading,
  setLoading,
}) => {
  const [editIndex, setEditIndex] = useState(-1);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [preferenceData, setPreferenceData] = useState({
    title: "",
    description: "",
    options: [],
  });

  const addPreference = () => {
    if (validationForm()) {
      if (editIndex !== -1) {
        const updatedPreferences = [...formData.preferences];
        updatedPreferences[editIndex] = preferenceData;
        handleChange({
          target: { name: "preferences", value: updatedPreferences },
        });
        setEditIndex(-1);
      } else {
        handleChange({
          target: {
            name: "preferences",
            value: [...formData.preferences, preferenceData],
          },
        });
      }
      cleanForm();
    }
  };

  const addOption = () => {
    setPreferenceData({
      ...preferenceData,
      options: [...preferenceData.options, { name: "" }],
    });
  };

  const removeOption = (index) => {
    const updatedOptions = [...preferenceData.options];
    updatedOptions.splice(index, 1);
    setPreferenceData({
      ...preferenceData,
      options: updatedOptions,
    });
  };

  const removePreference = (index) => {
    const updatedPreferences = [...formData.preferences];
    updatedPreferences.splice(index, 1);
    handleChange({
      target: { name: "preferences", value: updatedPreferences },
    });
  };

  const editPreference = (index) => {
    setEditIndex(index);
    setPreferenceData(formData.preferences[index]);
    toggle();
  };

  const handleCancel = () => {
    cleanForm();
  };

  const cleanForm = () => {
    setPreferenceData({
      title: "",
      description: "",
      options: [],
    });
    toggle();
  };

  const validationForm = () => {
    if (preferenceData.title !== "" && preferenceData.description !== "") {
      return true;
    } else {
      alert("Please fill in all fields!");
      return false;
    }
  };

  const handlePreSubmit = () => {
    setLoading(true);

    handleSubmit();
  };

  return (
    <Fragment>
      <div className="card-body">
      <h6 style={{marginBottom:'26px', fontWeight:'600'}}>Event Preferences</h6>
        {formData.preferences.map((preference, index) => (
          <div
            className="border rounded px-4 py-2 d-flex flex-wrap justify-content-between align-items-center gap-2 mb-3"
            key={index}
          >
            <div>
              <h5 style={{fontSize:'15px'}} className="mb-1 form-label">{preference.title}</h5>
              <small style={{fontSize:'12px'}} className="text-muted">{preference.description}</small>
            </div>
            <ul>
              {preference.options.map((option, optionIndex) => (
                <li className="text-muted" key={optionIndex}>
                  <small style={{fontSize:'12px'}}>{option.name}</small>
                </li>
              ))}
            </ul>
            <div>
              <button
                className="btn btn-sm btn-white text-blue-light d-flex align-items-center"
                onClick={() => editPreference(index)}
              >
                <BiPencil size={16} />
                <span className="ms-1" style={{ fontWeight: 600 }}>
                  Edit
                </span>
              </button>
              <button
                className="btn btn-sm btn-white text-gray-dark d-flex align-items-center"
                onClick={() => removePreference(index)}
              >
                <AiOutlineClose size={16} />
                <span className="ms-1" style={{ fontWeight: 600 }}>
                  Remove
                </span>
              </button>
            </div>
          </div>
        ))}
        <button
          onClick={toggle}
          className="btn btn-sm btn-white text-brown-primary d-flex align-items-center"
        >
          <AiOutlinePlus size={18} />
          <span className="ms-1" style={{ fontWeight: 600 }}>
            Add Preference
          </span>
        </button>
      </div>

      <div className="card-footer d-flex justify-content-between align-items-center bg-white py-3">
        <button className="btn btn-sm btn-outline-dark rounded-pill px-4 ms-2">
          Cancel
        </button>
        <div className="d-flex">
          <button
            onClick={prevStep}
            className="btn btn-sm btn-outline-warning rounded-pill px-4 ms-2"
          >
            Back
          </button>
          {loading ? (
            <button
              className="btn btn-sm bg-blue-primary rounded-pill px-4 ms-2"
              disabled
            >
              <Spinner
                as="span"
                variant="white"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="grow"
              />
            </button>
          ) : (
            <button
              onClick={handlePreSubmit}
              className="btn btn-sm bg-blue-primary rounded-pill px-4 ms-2"
            >
              Complete & Send
            </button>
          )}
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h5 className="text-center p-3">
            {editIndex !== -1 ? "Update" : "Add"} Preference
          </h5>
          <div className="row mb-2">
            <div className="col-12">
              <label className="form-label" htmlFor="inputTitle">
                Title
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="title"
                  id="inputTitle"
                  placeholder=""
                  value={preferenceData.title}
                  onChange={(e) =>
                    setPreferenceData({
                      ...preferenceData,
                      title: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12">
              <label className="form-label" htmlFor="inputDescription">
                Description
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="description"
                  id="inputDescription"
                  placeholder=""
                  value={preferenceData.description}
                  onChange={(e) =>
                    setPreferenceData({
                      ...preferenceData,
                      description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {preferenceData.options.map((option, index) => (
            <div className="row mb-2" key={index}>
              <div className="col-12">
                <label className="form-label" htmlFor={`inputOption${index}`}>
                  Option {index + 1}
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id={`inputOption${index}`}
                    placeholder=""
                    value={option.name}
                    onChange={(e) => {
                      const updatedOptions = [...preferenceData.options];
                      updatedOptions[index] = { name: e.target.value };
                      setPreferenceData({
                        ...preferenceData,
                        options: updatedOptions,
                      });
                    }}
                  />
                  <button
                    className="btn btn-white btn-sm"
                    onClick={() => removeOption(index)}
                  >
                    <AiOutlineClose size={16} />
                  </button>
                </div>
              </div>
            </div>
          ))}
          <button
            onClick={addOption}
            className="btn btn-sm btn-white text-brown-primary d-flex align-items-center"
          >
            <AiOutlinePlus size={18} />
            <span className="ms-1" style={{ fontWeight: 600 }}>
              Add Option
            </span>
          </button>
        </ModalBody>
        <ModalFooter className="border-0">
          <Button
            color="secondary"
            className="btn btn-sm rounded-pill px-4"
            size="sm"
            outline
            onClick={handleCancel}
          >
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="btn btn-sm bg-blue-primary rounded-pill px-4"
            size="sm"
            onClick={addPreference}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default EventPreferences;
