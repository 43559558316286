import React, { Fragment, useState } from "react";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const GuestList = ({
  formData,
  handleChange,
  prevStep,
  nextStep,
  loading,
  setLoading,
}) => {
  const [editIndex, setEditIndex] = useState(-1);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [guestData, setGuestData] = useState({
    name: "",
    title: "",
    company: "",
    email: "",
  });

  const addGuest = () => {
    if (validationForm()) {
      if (editIndex !== -1) {
        const updatedGuests = [...formData.guests];
        updatedGuests[editIndex] = guestData;
        handleChange({ target: { name: "guests", value: updatedGuests } });
        setEditIndex(-1);
      } else {
        handleChange({
          target: { name: "guests", value: [...formData.guests, guestData] },
        });
      }
      cleanForm();
    }
  };

  const removeGuest = (index) => {
    const updatedGuests = [...formData.guests];
    updatedGuests.splice(index, 1);
    handleChange({ target: { name: "guests", value: updatedGuests } });
  };

  const editGuest = (index) => {
    setEditIndex(index);
    setGuestData(formData.guests[index]);
    toggle();
  };

  const handleCancel = () => {
    cleanForm();
  };

  const cleanForm = () => {
    setGuestData({
      name: "",
      title: "",
      company: "",
      email: "",
    });
    toggle();
  };

  const validationForm = () => {
    if (
      guestData.name !== "" &&
      guestData.title !== "" &&
      guestData.company !== "" &&
      guestData.email !== ""
    ) {
      return true;
    } else {
      alert("Please fill in all fields!");
      return false;
    }
  };

  return (
    <Fragment>
      <div className="card-body">
        <h6
          style={{
            marginBottom: "26px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          Guest List
        </h6>
        <div id="GuestList">
          {formData.guests.map((guest, index) => {
            let pos = index + 1;
            return (
              <div key={index} className="border rounded mb-3">
                <div
                  className="bg-brown-light text-dark d-flex align-items-center justify-content-center"
                  style={{
                    width: "54px",
                    height: "28px",
                    borderTopLeftRadius: "10%",
                  }}
                >
                  <span className="fw-bolder">#{pos}</span>
                </div>
                <div className="px-4 py-2 d-flex flex-wrap justify-content-between align-items-center gap-2">
                  <div>
                    <h5 className="mb-1 form-label">
                      {guest.name} ({guest.title})
                    </h5>
                    <small className="text-muted">Name</small>
                  </div>
                  <div>
                    <p className="mb-1 text-dark">{guest.email}</p>
                    <small className="text-muted">Email</small>
                  </div>
                  <div>
                    <p className="mb-1 text-dark">{guest.company}</p>
                    <small className="text-muted">Company</small>
                  </div>
                  <div>
                    <button
                      className="btn btn-sm btn-white text-blue-light d-flex align-items-center"
                      onClick={() => editGuest(index)}
                    >
                      <BiPencil size={16} />
                      <span className="ms-1" style={{ fontWeight: 600 }}>
                        Edit
                      </span>
                    </button>
                    <button
                      className="btn btn-sm btn-white text-gray-dark d-flex align-items-center"
                      onClick={() => removeGuest(index)}
                    >
                      <AiOutlineClose size={16} />
                      <span className="ms-1" style={{ fontWeight: 600 }}>
                        Remove
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <button
          onClick={toggle}
          className="btn btn-sm btn-white text-brown-primary d-flex align-items-center"
        >
          <AiOutlinePlus size={18} />
          <span className="ms-1" style={{ fontWeight: 600 }}>
            Add Guest
          </span>
        </button>
      </div>
      <div className="card-footer d-flex justify-content-between align-items-center bg-white py-3">
        <button className="btn btn-sm btn-outline-dark rounded-pill px-4 ms-2">
          Cancel
        </button>
        <div className="d-flex">
          <button
            onClick={prevStep}
            className="btn btn-sm btn-outline-warning rounded-pill px-4 ms-2"
          >
            Back
          </button>
          <button
            onClick={nextStep}
            className="btn btn-sm bg-blue-primary rounded-pill px-4 ms-2"
          >
            Continue
          </button>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h5 className="text-center p-3">
            {editIndex !== -1 ? "Update" : "Add"} Guest
          </h5>
          <div className="row">
            <div className="col-12 mb-2">
              <label className="form-label" htmlFor="inputName">
                Name
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="name"
                  value={guestData.name}
                  onChange={(e) =>
                    setGuestData({ ...guestData, name: e.target.value })
                  }
                  id="inputName"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <label className="form-label" htmlFor="inputTitle">
                Title
              </label>
              <select
                className="form-select form-select-sm"
                name="title"
                defaultValue={guestData.title}
                onChange={(e) =>
                  setGuestData({ ...guestData, title: e.target.value })
                }
                id="inputTitle"
              >
                <option value={guestData.title}>{guestData.title}</option>

                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
              </select>
            </div>
            <div className="col-12 col-md-6 mb-2">
              <label className="form-label" htmlFor="inputCompany">
                Company
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="company"
                value={guestData.company}
                onChange={(e) =>
                  setGuestData({ ...guestData, company: e.target.value })
                }
                id="inputCompany"
                placeholder=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2">
              <label className="form-label" htmlFor="inputEmail">
                Email
              </label>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control form-control-sm"
                  name="email"
                  value={guestData.email}
                  onChange={(e) =>
                    setGuestData({ ...guestData, email: e.target.value })
                  }
                  id="inputEmail"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="border-0">
          <Button
            color="secondary"
            className="btn btn-sm rounded-pill px-4 "
            size="sm"
            outline
            onClick={handleCancel}
          >
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="btn btn-sm bg-blue-primary rounded-pill px-4"
            size="sm"
            onClick={addGuest}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default GuestList;
