import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePlus, AiOutlineInfoCircle } from "react-icons/ai";
import { TbCalendarDue } from "react-icons/tb";
import { BsSearch } from "react-icons/bs";
import "./index.css";
import { api } from "../../service/api";

const Home = () => {
  const [events, setEvents] = useState([]);

  const loadEvents = async () => {
    try {
      const response = await api.get('events');
      // console.log("resp - home", response.data)
      setEvents(response.data.events);
    } catch (error) {
      console.error(error.response.data)
    }
  };

  useEffect(() => {
    loadEvents();
  }, []);

  return (
    <Fragment>
      <div className="card">
        <div className="d-flex justify-content-between card-body">
          <form className="row row-cols-lg-auto g-3 align-items-center">
            <div className="col-12">
              <label className="form-label" htmlFor="inlineFormInputGroupUsername">Search Event</label>
              <div className="input-group">
                <div className="input-group-text bg-white"><BsSearch /></div>
                <input type="text" className="form-control form-control-sm" id="inlineFormInputGroupUsername" placeholder="" />
              </div>
            </div>
            <div className="col-12">
              <label className="form-label" htmlFor="filterDate">Date</label>
              <div className="input-group">
                <div className="input-group-text bg-white"><TbCalendarDue size={18} /></div>
                <input type="date" className="form-control form-control-sm" id="filterDate" placeholder="DD/MM/YYYY" />
              </div>
            </div>
            <div className="col-12">
              <label className="form-label text-white" htmlFor="filterDate">.</label>
              <div className="input-group justify-content-between">
                <button type="button" className="btn btn-sm btn-brown-primary rounded-pill px-4">Search</button>
                <Link to={"/event/create"} className="btn btn-sm bg-blue-primary rounded-pill px-4 d-lg-none"><AiOutlinePlus size={18} /> <span className="ps-1 fw-bold">New Event</span></Link>
              </div>
            </div>
          </form>
          <div className="d-flex align-items-center">
            <Link to={"/event/create"} className="btn btn-sm bg-blue-primary rounded-pill px-4 d-none d-lg-block"><AiOutlinePlus size={18} /> <span className="ps-1 fw-bold">New Event</span></Link>
          </div>
        </div>
      </div>
      {events && events.map((event, index) =>
        <div className="card mt-4" key={index}>
          <div className="d-flex flex-wrap justify-content-between align-items-center card-body">
            <div className="my-4">
              <h5 className="text-nowrap">{event.name}</h5>
              <p className="mb-0 text-gray-dark"><small>Created: <span>{event.created_at}</span></small></p>
            </div>
            <div className="d-flex justify-content-between align-items-center gap-5">
              <div className="text-center">
                <p className="form-label" htmlFor="filterDate">{event.guests.length}</p>
                <p className="mb-0 text-nowrap text-gray-dark">Invited</p>
              </div>
              <div className="text-center">
                <p className="form-label" htmlFor="filterDate">{event.responses.yes}</p>
                <p className="mb-0 text-nowrap text-green-primary">Yes</p>
              </div>
              <div className="text-center">
                <p className="form-label" htmlFor="filterDate">{event.responses.no}</p>
                <p className="mb-0 text-nowrap text-orange-primary">No</p>
              </div>
              <div className="align-items-center">
                <Link to={`/event/${event.id}`} className="btn btn-sm btn-white text-blue-light rounded-0 border-0"><AiOutlineInfoCircle size={18} /> <span className="ps-1 fw-bold">Details</span></Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Home;
