import React, { Fragment, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import GuestDetails from "./GuestDetails";
import { api } from "../../../../../service/api";

const Guests = ({ id }) => {
    const [guest, setGuest] = useState(null);
    const [guests, setGuests] = useState([]);
    
    useEffect(() => {
        loadGuests();
    }, [id]);

    const loadGuests = async () => {
        if (id) {
            try {
                const response = await api.get(`/scannedGuests/${id}`);
                console.log("Resp - scannedGuests", response.data)
                setGuests(response.data.guests);
            } catch (error) {
                console.error(error.response.data)
            }
        }
    };
     
    return (
        <Fragment>
            <div className="row px-3">
                <div className="col-12">
                    <div className="input-group">
                        <div className="input-group-text bg-white"><BsSearch size={18} /></div>
                        <input type="search" className="form-control form-control-sm" id="inputDate" placeholder="" />
                    </div>
                </div>
                <p className="my-3">100 Guests</p>
            </div>
            {guest ? (
                <div className="pb-4">
                    <div className="px-3">
                        <button
                            onClick={() => setGuest(null)}
                            className="btn btn-sm btn-danger"
                        >
                            Fechar
                        </button>
                    </div>
                    <GuestDetails guest={guest} />
                </div>
            ) : (<>
                {guests.map((item, index) => <div key={index} className="border-bottom mb-2" style={{cursor: "pointer"}}>
                    <div className="px-3" onClick={() => setGuest(item)}>
                        <h6 className="mb-0 fw-bold">{item.title} {item.name}</h6>
                        <p className="mb-0">{item.email}</p>
                        <p className="mb-1 text-muted"><small>Scanned: {item.scanned_at}</small></p>
                    </div>
                </div>)}
            </>)}
        </Fragment>
    );
}

export default Guests;