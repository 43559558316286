import React from "react";
import { Link } from "react-router-dom";
import scann from "../../../assets/mobile/scan.png";
import background from "../../../assets/mobile/background.png";
import "../../../assets/GlobalStyle.css";
import "./index.css";

const WelcomeMobile = () => {

    return (
        <div className="bg-white">
            <div className="container d-flex flex-column justify-content-center align-items-center gap-5" style={{ height: "100vh", maxWidth: "360px", backgroundColor: "#FFFFFF" }}>
                <img src={scann} alt="" />
                <img src={background} alt="" />
                <div className="text-center">
                    <h5 className="mb-4 fw-bolder">Welcome</h5>
                    <p className="text-center mb-4">Login to your account and find the key to your event with our efficient and easy to use system. </p>
                    <Link to={"/mobile/getStarted"} className="btn bg-blue-primary w-100">Get Started</Link>
                </div>
            </div>
        </div>
    );
};

export default WelcomeMobile;
