import React from "react";
import scann from "../../../assets/mobile/scan.png";
import background from "../../../assets/mobile/background.png";
import "./index.css";

const Splash = () => {

    return (
        <div className="d-flex flex-column justify-content-center align-items-center gap-5" style={{ height: "100vh", backgroundColor: "#A0C0D0" }}>
            <img src={scann} alt="QRCode" />
            <img src={background} alt="background" />
        </div>
    );
};

export default Splash;
