export const TOKEN_KEY = "apitoken";
export const USERDATA = "userdata";

export const isAuthenticated = () => {
  console.log("isAuthenticated called");
  const token = localStorage.getItem(TOKEN_KEY);
  console.log("token:", token);
  return token !== null;
};

export const getToken = () => {
  console.log("getToken called");
  const token = localStorage.getItem(TOKEN_KEY);
  console.log("token:", token);
  return token;
};
export const getUser = () => {
  const user = localStorage.getItem(USERDATA);

  return JSON.parse(user);
};

export const login = (token) => {
  console.log("login called with token:", token);
  localStorage.setItem(TOKEN_KEY, token);
};
export const saveUser = (user) => {
  const userFormatted = JSON.stringify(user);
  console.log("saveuser called with user:", userFormatted);
  localStorage.setItem(USERDATA, userFormatted);
  console.log("user stored in localStorage");
};

export const logout = () => {
  console.log("logout called");
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USERDATA);
  console.log("token removed from localStorage");
};
