import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./layouts/index";
import Login from "./pages/Login";
import { useAuth } from "./context/AuthContext";

import Home from "./pages/Home";
import CreateEvent from "./pages/Event/Create";
import DetailsEvent from "./pages/Event/Details";
import Invitee from "./pages/Event/Invitee";
import WelcomeMobile from "./pages/Mobile/Welcome";
import Splash from "./pages/Mobile/Splash";
import GetStarted from "./pages/Mobile/GetStarted";
import ScanQRCode from "./pages/Mobile/Home/ScanQRCode";
import HomeMobile from "./pages/Mobile/Home";
import GuestsPreferences from "./pages/Mobile/Home/GuestsPreferences";
import Profile from "./pages/Mobile/Home/Profile";

const Router = () => {
  return (
    <Routes>
      {/*  =================Home ROUTES=================== */}
      <Route
        path="/home"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        path="/event/create"
        element={
          <RequireAuth>
            <CreateEvent />
          </RequireAuth>
        }
      />
      <Route
        path="/event/:id"
        element={
          <RequireAuth>
            <DetailsEvent />
          </RequireAuth>
        }
      />
      <Route path="/invitee" element={<Invitee />} />

      <Route path="/" element={<Login />} />


      <Route path="/mobile" element={<Splash />} />
      <Route path="/mobile/welcome" element={<WelcomeMobile />} />
      <Route path="/mobile/getStarted" element={<GetStarted />} />
      <Route path="/mobile/home" element={<MobileAuth><GuestsPreferences /></MobileAuth>} />
      <Route path="/mobile/scan/qrcode" element={<MobileAuth><ScanQRCode /></MobileAuth>} />
      <Route path="/mobile/profile" element={<MobileAuth><Profile /></MobileAuth>} />
      <Route path="*" element={<h1>Resource Not Found</h1>} />
    </Routes>
  );
};

const RequireAuth = ({ children }) => {
  const { authenticated } = useAuth();

  return authenticated === true ? (
    <Layout>{children}</Layout>
  ) : (
    <Navigate to="/" replace />
  );
};

const MobileAuth = ({ children }) => {
  const { authenticated } = useAuth();

  return authenticated === true ? (
    <HomeMobile>{children}</HomeMobile>
  ) : (
    <Navigate to="/mobile/getStarted" replace />
  );
};

export default Router;
